import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dueDate", "actualDueDate"];

  connect() {
    // Initialize the restrictions for both due_date and actual_due_date
    this.restrictWeekends(this.dueDateTarget);
    this.restrictWeekends(this.actualDueDateTarget);
  }

  restrictWeekends(target) {
    const datepicker = $(target); // Using jQuery to get the datepicker (assuming you're using Bootstrap or a jQuery datepicker)

    datepicker.datepicker({
      beforeShowDay: (date) => {
        const day = date.getDay(); // 0: Sunday, 6: Saturday
        if (day === 0 || day === 6) {
          return { disabled: true, class: "disabled-date" }; // Disable weekends
        } else {
          return true; // Enable weekdays
        }
      },
    });
  }
}
